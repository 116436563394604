import React from 'react'
import Logo from '../components/Logo'
import './globalStyles.css'
import './Footer.css'
import { Link } from 'gatsby'
import {
  Smartphone,
  Mail,
  Printer,
  MapPin,
  Facebook,
  Linkedin,
} from 'react-feather'

export default () => (
  <div>
    <footer className="footer">
      <div className="credit">
        <div className="container text-center mx-auto">
          <li>
            <MapPin />
            <br />
            <br />
            7851 Mission Center Court, Suite 115 San Diego, CA&nbsp;92108
          </li>
          <li>
            <Mail /> <br />
            <br />
            info@massmarketingservices.com
          </li>
          <li>
            <Smartphone /> <br />
            <br />
            619.297.0203
          </li>
          <li>
            <Printer /> <br />
            <br /> 619.297.0449
          </li>
        </div>
        <div className="container p-4">
          <div className="row col-lg-4 mx-auto text-center">
            <div className="col-2 mx-auto text-center">
              <a
                href="https://www.facebook.com/pages/Mass-Marketing-Service/925744797511968"
                target="_blank"
                rel="noopener noreferrer"
                alt="Mass Marketing Services Facebook"
                className=""
              >
                <div className="Footer--Button">
                  <Facebook
                    className="Footer-Button-Icon"
                    aria-label="Mass Marketing Services Facebook"
                  />
                </div>
              </a>
            </div>
            <div className="col-2 mx-auto text-center">
              <a
                href="https://www.linkedin.com/company/mass-marketing-services-llc/"
                target="_blank"
                rel="noopener noreferrer"
                alt="Mass Marketing Services LinkedIn"
              >
                <div className="Footer--Button">
                  <Linkedin
                    className="Footer-Button-Icon"
                    aria-label="Mass Marketing Services LinkedIn"
                  />
                </div>
              </a>
            </div>
            <div className="col-2 mx-auto text-center">
              <Link to="/contact/">
                <div className="Footer--Button">
                  <Mail
                    className="Footer-Button-Icon"
                    aria-label="Contact Mass Marketing Services"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <span className="text-center">
          © {new Date().getFullYear()}&nbsp;
          <a href="https://massmarketingservices.com">
            Mass Marketing Services
          </a>
          .
        </span>
      </div>
    </footer>
  </div>
)
